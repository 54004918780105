@import 'colors';
@import 'constants';
@import 'fonts';
@import 'forms';
@import 'homes';
@import 'navigation';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body {
	margin: 0;
	padding: 0;
	font-family: $font-light;
	background-color: #ffffff !important;
	overflow-x: hidden;
	height: 100%;
	width: 100%;
}

#page_wrapper {
	box-sizing: border-box;
	// min-height: calc(100vh - 6.233rem); // 6.233rem
	min-height: calc(100vh - 9.5rem); // 6.233rem
	overflow: hidden;
	background-color: transparent;
	padding-top: 4rem;
	// height: 100%;
	width: 100%;
}

.brand_highlighted_text_light {
	font-size: 20px;
	text-transform: capitalize !important;
	font-family: $font-light;
	font-size: inherit;
	color: $default-theme-green;
}

.brand_highlighted_text {
	font-family: $font-condensed;
	font-size: inherit;
	color: $dark-green;
}

.brand_highlighted_text_weda {
	font-family: $font-bold;
	font-size: inherit;
	color: $light-yellow;
}

.brand_highlighted_text_weda_green {
	font-family: $font-bold;
	font-size: inherit;
	color:$dark-green;
}

.secondary_highlighted_text {
	font-family: $font-medium, sans-serif;
	color: $default-font-orange;
}

.w-text {
	font-family: $font-bold, sans-serif;
}

/*
===============================================================================================
                       MODAL UI
===============================================================================================
*/
.ReactModal__Overlay,
.ReactModal__Overlay--after-open {
	z-index: 99999 !important;
	background: #0e2c39 0% 0% no-repeat padding-box !important;
	opacity: 1;

	.loader_box {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.loader_text {
			font-family: $font-condensed;
			color: $default-font-black;
			font-size: '3rem';
			margin-top: 1rem;
		}
	}
}

/**
--------------------------------------------------------
webapp footer bar
----------------------------------------------------------
*/

/**
--------------------------------------------------------
Contact us Page
----------------------------------------------------------
*/

.heading_box_sub_text {
	display: flex;

	@media only screen and (max-width: 425px) {
		display: none;
	}
}

.reset-button {
	font-family: $font-light, sans-serif !important;
	font-size: 16px;
	background-color: transparent;
	border: 0px solid #0e2c39;
	letter-spacing: 0px;
	color: #0e2c39 !important;
	font-weight: bold;
	padding: 5px 5px;

	&:focus {
		border: none;
		box-shadow: none;
	}
}

.contact_form {
	border: 1px solid #ebfffb; // #39CFBA; // FEF9E9
	background: #ebfffb 0% 0% no-repeat padding-box;
	opacity: 1;
	box-shadow: -3px 3px 6px #00000029;
	position: relative;
	height: 100%;

	@media only screen and (max-width: 900px) {
		&:before,
		&:after {
			display: none;
		}
	}

	&:before {
		content: '\2022';
		font-size: 1em;
		font-weight: bold;
		position: absolute;
		right: 100%;
		top: calc(1rem + 12.33%);
		z-index: 1;
		color: #0e2c39; //#dbb63a;
		opacity: 0.6;
		transform: scale(3);
	}

	&:after {
		content: '';
		display: block;
		position: absolute;
		right: 100%;
		top: calc(1rem + 8.33%);
		// margin-top: -50px;
		width: 0;
		height: 0;
		border-top: 40px solid transparent;
		border-right: 40px solid #ebfffb; // FEF9E9
		border-bottom: 40px solid transparent;
		border-left: 40px solid transparent;

		@media only screen and (max-width: 900px) {
			display: none;
		}
	}
}
