.row_box {
	margin-bottom: 0rem;
	box-sizing: border-box;
}

/*
---------------------------------------------------------------------------
WARAPPER_BOX
---------------------------------------------------------------------------
*/
.content_box {
	margin: 0;
	padding: 0;
	border: 0px dotted grey;
	position: relative;
	box-sizing: border-box;
	object-fit: cover;
	overflow: hidden;
	padding-top: 2em;
}

/*
---------------------------------------------------------------------------
MAIN_BANNER
---------------------------------------------------------------------------
*/

.banner_row_box {
	background-image: url('../images/banner_full.png');
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
	width: auto;
	height: 90vh;
}

/*
---------------------------------------------------------------------------
SIGNUP_BANNER
---------------------------------------------------------------------------
*/



/*
---------------------------------------------------------------------------
FLSH_VIDEO_BANNER
---------------------------------------------------------------------------
*/

.video_row_box {
	padding: 0;
	width: 100%;
	height: auto;
	background-color: #0e2c39;
}

/*
---------------------------------------------------------------------------
MAIN_TOP_BANNER
---------------------------------------------------------------------------
*/

.heading_H1 {
	font-family: $font-bold;
	font-size: clamp(25px, 3.5vw, 80px);
	color: $default-font-black;

	&.top_header {
		margin: 0.5em 0.3em;
	}
}

.heading_H2 {
	font-family: $font-medium;
	font-size: calc(2vw - 0.2rem);
	color: $default-font-black;
}

.heading_H4 {
	font-family: $font-medium;
	font-size: clamp(1rem, 2.5vw, 2rem);
	color: $default-font-black;
}

.heading_H5 {
	font-family: $font-light;
	font-size: clamp(1rem, 2.5vw, 2rem);
	color: $default-font-black;
}

.heading_H6 {
	font-size: clamp(1rem, 2.5vw, 2rem);
	padding: 0em 0em 0em 5em;
	&.top_header {
		margin: 0.5em 0.3em;
	}
}

.heading_H7 {
	font-family: $font-light;
	font-size: clamp(1rem, 2.5vw, 2rem);
	padding: 0em 0em 0em 5.5em;
	// &.top_header {
	// 	margin: 0.5em 0.3em;
	// }
	color: $dark-green;
}

.heading_H8 {
	font-family: $font-bold;
	padding: 0em 0em 0em 5.5em;
	font-size: clamp(1rem, 2.5vw, 2rem);
	color: $dark-green-2;
}
/*
---------------------------------------------------------------------------
SIGNUP_BANNER
---------------------------------------------------------------------------
*/

.signup_row_box {
	background-image: url('../images/wedawaru_signup.png');
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
	width: auto;
	height: 80vh;
}

.signup_headline_text {
	text-align: center;
	font-size: clamp(1.5rem, 2.5vw, 4rem);
	padding: 1em 0em 0em 0em;
	font-family: $font-light;
}

.sub_headline_text {
	text-align: center;
	font-size: clamp(20px, 8vw, 20px);
	padding-top: 8em;
	font-family: $font-light;
}
.signup_link_text {
	text-align: right;
	font-size: 40px;
	padding-top: 10px;
	font-family: $font-light;
}

.signup_bottom_left {
	width: 100%;
	background-image: linear-gradient(to right, #ffffff, #f5f5f5, #f5f5f5, #f5f5f5, #f5f5f5);

	@media only screen and (max-width: 768px) {
		background-image: linear-gradient(to right, #ffffff, #f5f5f5, #f5f5f5, #f5f5f5, #ffffff);
	}

	&:after,
	:before {
		left: 65.33%;
		top: 67%;
		border: solid transparent;
		content: ' ';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	.signup_card {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-self: center;
	}
}

/*
---------------------------------------------------------------------------
PATIEANT_CONTENT_BLOCK
---------------------------------------------------------------------------
*/

.patient_row_box {
	position: relative;
	background-color: #ebfffb;
	box-sizing: border-box;
	clear: both;

	.image_box_block {
		width: 100%;
		height: 100%;
		padding: 0;
		margin: 0;
		position: relative;
		// TODO responsice
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;

		.patient_img_hand {
			text-align: center;
			display: block;
			position: relative;
			clear: both;
		}

		.caption_text {
			font-family: $font-medium !important;
			text-align: center;
			text-transform: uppercase;
			margin-left: -4.5em;
			font-size: 14px;
			font-weight: 600;
			display: block;
			letter-spacing: 1px;
			margin-bottom: -1.2em;
			padding-top: 2em;
		}
	}

	// Main block toggle from desk -> mobile
	.main_heading_block {
		font-family: $font-bold;
		font-size: clamp(20px, 2.8vw, 38px);
		padding: 2em 0em 0em 0em;
	}

	.text_box_block {
		//border: 0px dotted orangered;
		width: 100%;
		height: 100%;
		color: $default-font-black;

		.bullet_point_block {
			text-align: left;
			padding: 3.5em 0em 0em 0em;

			.bullet_point_heading {
				font-family: $font-bold;
				font-size: clamp(20px, 2.2vw, 30px);
				text-transform: uppercase;
				color: $default-theme-green;
			}
		}
	}
}

/*
---------------------------------------------------------------------------
DISPENSARY_CONTENT_BLOCK
---------------------------------------------------------------------------
*/

.bookappt_row_box {
	background-image: url('../images/wedawaru_book_appt.png');
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
	width: auto;
	height: 80vh;
	padding-top: 2em;
}

.dispensary_row_box {
	position: relative;
	background-color: #f5f5f5;
	box-sizing: border-box;
	clear: both;

	.image_box_block {
		width: 100%;
		height: 100%;
		padding: 0;
		margin: 0;
		position: relative;
		// TODO responsice
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;

		.dispensary_img_hand {
			// width: 100%;
			// height: auto;
			// text-align: center;
			// display: block;
			// TODO responsive
			//margin-left: -10em;
			//padding-top: -1em;
			position: relative;
			clear: both;
		}

		.caption_text {
			font-family: $font-medium !important;
			text-align: center;
			text-transform: uppercase;
			margin-right: 0em;
			font-size: 14px;
			font-weight: 600;
			display: block;
			letter-spacing: 1px;
			margin-bottom: 0em;
			padding-top: 2em;
		}
	}

	.main_heading_block {
		text-align: left;
		font-family: $font-bold;
		font-size: clamp(20px, 2.8vw, 38px);
		letter-spacing: -0.3px;
		padding-top: 2em;
	}

	.text_box_block {
		//border: 1px dotted orangered;
		width: 100%;
		height: 100%;
		color: $default-font-black;

		.bullet_point_block {
			text-align: left;
			padding: 3.5em 0em 0em 0em;

			.bullet_point_heading {
				font-family: $font-bold;
				font-size: clamp(20px, 2.2vw, 30px);
				text-transform: uppercase;
				color: $default-theme-green;
			}
		}
	}
}

/*
---------------------------------------------------------------------------
GOOGLEAPPS_CONTENT_BLOCK
---------------------------------------------------------------------------
*/

.googleapps_row_box {
	background-image: linear-gradient(to right, #f1f9ff, #f6faff, #f1f9ff, #fdfdff, #ffffff);

	@media only screen and (max-width: 768px) {
		background: #ffffff 0% 0% no-repeat padding-box;
	}

	position: relative;
	box-sizing: border-box;
	clear: both;
	width: 100%;
	height: auto;

	.left_block,
	.right_block {
		width: 100%;
		height: auto;
		box-sizing: border-box;

		.heading_box {
			font-family: $font-bold, sans-serif;
			font-size: clamp(22px, 2.5vw, 40px);
			color: $default-font-black;
			text-align: center;
			padding: 1em 0em;
		}

		.logo_box {
			width: 100%;
			height: auto;
			clear: both;

			.logo_box_icons {
				width: 100%;
				height: auto;

				img {
					width: 100%;
					height: 100%;
				}

				.icon_top {
					//border: 1px dotted pink;
					box-sizing: border-box;
				}

				.icon_bottom {
					//border: 1px dotted indigo;
					box-sizing: border-box;

					.tags {
						font-family: $font-medium, sans-serif;
						font-size: clamp(20px, 2vw, 30px);
						text-align: center;
						padding: 0.5em 0em;
					}
				}
			}
		}
	}

	.left_block {
		background: #f1f9ff 0% 0% no-repeat padding-box;
	}

	.right_block {
		background: #ffffff 0% 0% no-repeat padding-box;
	}
}
