// define main colors
$main-white: #ffffff;
$main-black: #000000;
$main-light-gray: #f5f5f5;
$default-theme-green: #39cfba;
$dark-green: #159782;

$ezdoc-theme-font-green: #39cfba;

$default-navbar-bgcolor: #ebfffb;
$default-footer-bgcolor: #0e2c39;
$default-font-black: #0e2c39;
$default-font-orange: #fd9976;

$faded-gray:#00000029;
$dark_gray:#C1C1C1;
$light-brown:#DFC19680;
$cream :#FEF9E9;
$darky-gray:#707070;
$dark-green:#0B6837;
$light-yellow:#F9DC7C;
$dark-green-2:#006837;



