.mobile_menu {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	justify-content: center;
	background-color: #006837; // rgba(0, 0, 0, 0.8);
	width: 100%;
	position: absolute;
	left: 0;
	top: 100.9%;
	margin: 0;
	height: 50vh;

	box-shadow: 0px 2px 6px #00000029;

	animation: navloading 0.3s ease-in;

	.navbar_link_mobile {
		text-decoration: none;
		font-family: $font-light;
		font-size: 16px !important;
		font-weight: 600;
		color: #ffffff;
		display: flex;
		flex-direction: column;
		justify-content: center;
		justify-content: center;
		padding: 1em 2.5em;
		border-bottom: 1px solid rgba(146, 146, 146, 0.2);
		color: #ffffff;

		&:hover {
			cursor: pointer;
			transition: all 0.2s ease;
			color: #F9DC7C;
			letter-spacing: 0px;
			font-weight: 500;
		}
	}
}

.navbar_link {
	text-decoration: none;
	color: #ffffff;
	font-family: $font-light;
	font-size: 16px !important;
	font-weight: 600;
	//letter-spacing: 0.0333rem;

	&:after {
		transition: all 0.2s ease;
		border-bottom: 4px solid transparent;
		content: '';
		width: 10%;
		display: block;
		position: relative;
		left: 0;
		bottom: 0;
		top: 20px;
	}

	&:hover {
		cursor: pointer;
		transition: all 0.2s ease;
		color: #F9DC7C;

		&:after {
			width: 100%;
			border-bottom-color: #F9DC7C;
		}
	}
}

.nav_link_actived {
	color: #ffffff;
	cursor: not-allowed;

	&:after {
		//transition: all 0.2s ease;
		border-bottom: 4px solid transparent;
		content: '';
		width: 100%;
		display: block;
		position: relative;
		left: 0;
		bottom: 0;
		top: 20px;
		border-bottom-color: #F9DC7C;
		cursor: pointer;
		transition: all 0.2s ease;
		color: #F9DC7C;
	}
}

@keyframes navloading {
	0% {
		height: 10vh;
	}

	20% {
		height: 30vh;
	}

	50% {
		height: 50vh;
	}

	60% {
		height: 60vh;
	}
}
